<template>
    <div>
        <v-radio-group v-model="radioValue" :rules="isRequired ? rules : []">
            <p v-if="hintText">{{ hintText }}</p>
            <v-radio
                v-for="option in options"
                :key="option.id"
                :label="getLocalizedValue(option.option)"
                :value="option.id"
                @change="updateAnswer(option.id)"
            ></v-radio>
        </v-radio-group>
        <v-btn v-if="radioValue.length > 0" text small @click="radioValue = ''">{{ $t('fields.radio.reset') }}</v-btn>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'Radio',
    mixins: [
        GetLocalizedValue
    ],
    props: {
        options: {
            type: Array,
            default: () => {
                return [];
            }
        },
        hintText: {
            default: null
        },
        isRequired: {
            default: true
        },
        questionId: {}
    },
    data() {
        return {
            radioValue: '',
            rules: [
                v => !!v || this.$t('fields.general.rules.required')
            ]
        };
    },
    computed: {
        ...mapState('surveys', ['currentSurveyAnswers'])
    },
    methods: {
        updateAnswer(id) {
            this.$emit('updateAnswer', id);
        },
        checkForAnswer() {
            if (this.currentSurveyAnswers) {
                this.currentSurveyAnswers.forEach((answer) => {
                    if (answer[this.questionId]) {
                        this.radioValue = answer[this.questionId];
                    }
                });
            }
        }
    },
    created() {
        this.checkForAnswer();
    }
};
</script>
